<template>
  <div v-if="user != false">
    <b-card class="card-custom mt-3 card-titre sticky-top" no-body style="z-index: 25; !important">
      <template #header>
        <div class="card-title">
          <h3 class="card-label">Ref<strong> #{{user.id}} - {{user.prenom}} {{user.nom}}</strong></h3>
        </div>
        <div class="card-title text-center">
          <b-button :variant="$api.getParam('UserStatus', user.status_id).color" size="lg">{{$api.getParam('UserStatus', user.status_id).titre}}</b-button>
        </div>
        <div class="card-title">
          <b-dropdown v-b-tooltip.hover title="Changer statut" no-caret class="mr-1" size="sm">
            <template #button-content>
              <i class="fas fa-thermometer-half"></i>
            </template>
            <b-dropdown-item v-for="(s,i) in $api.params.UserStatus" :key="i"  :active="user.status_id === s.id" @click="user.status_id = s.id">{{s.titre}}</b-dropdown-item>
          </b-dropdown>
          <b-button v-b-tooltip.hover title="Sauvegarder" variant="success" class="btn-icon" @click="save()" size="sm">
            <i v-if="save_loader === false" class="fas fa-save"></i>
            <i v-else class="fas fa-spinner fa-spin"></i>
          </b-button>
        </div>
      </template>
    </b-card>
    <b-card class="mt-3">
      <form ref="form"> 
        <b-row>
          <FieldForm model="User" :obj="user" field="nom"/>
          <FieldForm model="User" :obj="user" field="prenom"/>
          <FieldForm model="User" :obj="user" field="telephone"/>
          <FieldForm model="User" :obj="user" field="email"/>
          <FieldForm model="User" :obj="user" field="password"/>          
          <FieldForm v-if="$api.acl(21)" model="User" :obj="user" field="nb_lead_jour"/>
          <FieldForm v-if="$api.acl(20)" model="User" :obj="user" field="role_id"/>               
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label="Vrai prenom">
              <Input v-model="user.vrai.prenom" placeholder="Numero" type="text"/>
            </b-form-group>
          </b-col>                    
          <b-col md="6">
            <b-form-group label="Vrai nom">
              <Input v-model="user.vrai.nom" placeholder="Numero" type="text"/>
            </b-form-group>
          </b-col>                    
        </b-row>
        <b-row v-if="$api.acl(22)">
          <b-col md="6">
            <b-form-group label="">
              <template v-slot:label>
                Prefix <b-button variant="primary" class="btn-icon ml-2" size="xs" @click="addPrefix()"><i class="fa fa-plus"></i></b-button>
              </template>
              <b-row v-for="(v, i) in user.pbx_prefix" :key="i" :class="i > 0 ? 'mt-2' : ''">
                <b-col md="4">
                  <Input v-model="v.numero" placeholder="Numero" type="text" :required="true"/>
                </b-col>
                <b-col md="4">
                  <Input v-model="v.code" placeholder="Code" type="text" :required="true"/>
                </b-col>
                <b-col md="2" class="p-2">
                  <Input v-model="v.defaut" placeholder="Defaut" type="checkbox" :required="true"/>
                </b-col>
                <b-col md="2">
                  <b-button variant="danger" class="btn-icon" size="sm" @click="removePrefix(i)"> <i class="fa fa-trash"></i></b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <FieldForm model="User" :obj="user" field="pbx_id"/>
          <FieldForm model="User" :obj="user" field="pbx_chat"/>
        </b-row>
        <b-row v-if="$api.acl(20)">
          <b-col md="12">
            <b-form-group label="Access au utilisateurs">
              <Input v-model="user.user_id" type="select-model" :options="users"/>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-3">
            <b-form-group label="Droits">
              <treeselect :multiple="true" :options="acl" placeholder="Selectionnez les droits" v-model="user.acl" :default-expand-level="1" value-consists-of="LEAF_PRIORITY"/>
            </b-form-group>
          </b-col>
        </b-row>        
      </form>
    </b-card>
  </div>
</template>
<script>
import Input from '@/components/InputForm';
import FieldForm from '@/components/FieldForm';

export default {
  name: "UtilisateurNouveau",
  components: {
    FieldForm, Input
  },
  data(){
    return{
      tabIndex:0,
      user: false,
      save_loader:false,
      users:false,
      acl: []
    }
  },
  watch:{
  },
  computed:{
  },
  beforeMount() {
    this.$api.ajax('/user/all', null, res => {
      if(res.status === true){
        this.users = res.data;
      }
    });
    this.$api.ajax('/acl/liste', null, res => {
      if(res.status === true){
        this.acl = res.data;
      }
    });
  },
  mounted() {
    if(this.$route.params.id === undefined){
      this.$api.ajax('/user/new', null, res => {
        if(res.status){
          this.user = res.data;
        }
      })
    }else{
      this.$api.ajax('/user/'+this.$route.params.id, null, res => {
        if(res.status){
          this.user = res.data;
        }
      })
    }
  },
  methods: {
    checkForm(){
      var res = true;
      if(this.$refs.form.reportValidity() === false){
        res = false;
      }
      return res;
    },
    save(){
      if(this.save_loader === true){
        return false;
      }
      if(this.checkForm() === false){
        return false;
      };
      this.save_loader = true;
      this.$api.ajax('/user/create', this.user, res => {
        if(res.status === true){
          this.user = res.data;
          if(this.$route.path.indexOf("nouveau") > -1){
            this.$router.push({path:"/utilisateur/detail/"+this.user.id});
          }
          this.$bvToast.toast('Sauvegarder', {
            title: 'Sauvegarder',
            variant: 'success',
            solid: true
          });
        }
        this.save_loader = false;
      })
    },
    addPrefix(){
      var defaut = this.user.pbx_prefix.lenght === 0 ? true : false;      
      this.user.pbx_prefix.push({
        numero:null,
        code:null,
        defaut:defaut
      });
    },
    removePrefix(i){
      this.user.pbx_prefix.splice(i, 1);
    },    
  }
}
</script>
<style>
</style>
